import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import Form from "./Form";
import Footer from "./Footer";
import imageSuseUvod from "../catalog/SuseUvod.png";
import imageSuseMetodologija1 from "../catalog/SuseMetodologija1.png";
import imageSuseMetodologija2 from "../catalog/SuseMetodologija2.png";
import imageSuseTrendovi from "../catalog/SuseTrendovi.png";
import imageSuseUticaj from "../catalog/SuseUticaj.png";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import ReactHtmlParser from 'html-react-parser';

export default function Suse () {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const isTabletOrLarger = useMediaQuery("only screen and (min-width: 769px)");
  const [activeTab, setActiveTab] = useState("uvod");
  const [activePodaciTab, setActivePodaciTab] = useState("suse-u-proslosti");

  const [spei, setSpei] = useState("SPEI-3");
  const [month, setMonth] = useState("Jan");
  const [year, setYear] = useState("1950");
  const [variationP, setVariationP] = useState("25p");
  const [imageSrc, setImageSrc] = useState(""); // Držimo putanju slike

  // Učitaj slike iz foldera
  const images = require.context('../slikeSuseUProslosti', false, /\.(png|jpe?g|svg)$/);
  const imagesBuducnost = require.context('../slikeBuducnostSuse', false, /\.(png|jpe?g|svg)$/);

  const getSelectedImage = () => {
    const monthMap = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
    const monthNumber = monthMap[month];
    const speiNumber = spei.split('-')[1];
    const imageName = `spei${speiNumber}_${monthNumber}_${year}.png`;

    try {
      return images(`./${imageName}`);
    } catch (error) {
      return "/images/default.png";
    }
  };

  const getSelectedImageForProjekcije = () => {
    const monthMap = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      June: 6,
      July: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
    const monthNumber = monthMap[month];
    const speiNumber = spei.split("-")[1];
    const variation = variationP;
    const imageName = `spei${speiNumber}_${monthNumber}_${year}_${variation}.png`;

    try {
      return imagesBuducnost(`./${imageName}`);
    } catch (error) {
      return imagesBuducnost(`./spei3_1_2041-2060_25p.png`);
    }
  };

  // useEffect za ažuriranje slike
  useEffect(() => {
    if (activePodaciTab === "suse-u-proslosti") {
      setImageSrc(getSelectedImage());
    } else if (activePodaciTab === "projekcije-za-buducnost") {
      setImageSrc(getSelectedImageForProjekcije());
    }
  }, [spei, month, year, variationP, activePodaciTab]);

  const getDownloadDataLink = () => {
    const monthMap = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
    const monthNumber = monthMap[month];
    const speiNumber = spei.split('-')[1];
    const dataFileName = `spei${speiNumber}_${monthNumber}_${year}.nc`;
    const dataFilePath = `/izdvojeniPodaci/${dataFileName}`;
    return dataFilePath;
  };

  const getDownloadDataLinkForProjekcije = () => {
    const dataFileName = `spei${spei.split('-')[1]}_${month}_${year}_${variationP}.nc`;
    return `/fajloviBuducnost/${dataFileName}`;
  };

  const data = [
    {
      label: "UVOD",
      value: "uvod",
      desc:`<h2>UVOD</h2>
      <p>Na grafiku su prikazane različite vrste suše i njihovi prateći procesi. Na primer, deficit padavina može dovesti do meteorološke suše, ali povećana evapotranspiracija usled visoke temperature može dovodi do poljoprivredne suše, na koju može uticati i tip zemljišta, odnosno vrsta vegetacije na određenom području. Različiti tipovi zemljišta i različita vegetacija (usevi) mogu reagovati različito na meteorološku sušu. (Cook et al., 2018; Van Lun, 2015)</p>` + `<p></p>`,
      image: `<img src="${imageSuseUvod}" alt="Suse Uvod" style="max-width:100%;height:auto;">`,
      desc2:`<p></p>`,
    },
    {
      label: "METODOLOGIJA",
      value: "metodologija",
      desc:`<h2>METODOLOGIJA</h2>
      <p>Za praćene suše koriste se različiti indeksi, neki od njih prate samo deficit padavina, dok drugi prate deficit vode u tlu ili rekama. Uz svaki ideks obično idu i odgovarajuće kategorije kojim se karakteriše intenzitet suše, Djurdjevic, 2020</p>`, 
      image: `<img src="${imageSuseMetodologija1}" alt="Suse Uvod" style="max-width:100%;height:auto;">` + 
      `<img src="${imageSuseMetodologija2}" alt="Suse Uvod" style="max-width:35%;height:auto;">`,
      desc2:`<p></p>`,
    },
    {
      label: "TRENDOVI",
      value: "trendovi",
      desc:`<h2>TRENDOVI</h2>
      <p>U Srbiji je detektovana povećana ulestalost jakih i ekstremnih suša koje se javljaju tokom toplijeg dela godne … Djurdjevic et al. 2023 Na slici je prikazana vremenska serija SEPI 6 indeksa za Srbiju i Beograd
      </p>`,
      image: `<img src="${imageSuseTrendovi}" alt="Suse Uvod" style="max-width:100%;height:auto;">`,
      desc2:`<p></p>`,
    },
    {
      label: "UTICAJ",
      value: "uticaj",
      desc:`<h2>UTICAJ</h2>
      <p>U Srbiji je detektovana povećana ulestalost jakih i ekstremnih suša koje se javljaju tokom toplijeg dela godne … Djurdjevic et al. 2023 Na slici je prikazana vremenska serija SEPI 6 indeksa za Srbiju i Beograd
      </p>`,
      image: `<img src="${imageSuseUticaj}" alt="Suse Uvod" style="max-width:100%;height:auto;">`,
      desc2:`<p></p>`,
    },
    {
      label: "PODACI",
      value: "podaci",
      tabs: [
        {
          label: "Suše u prošlosti",
          value: "suse-u-proslosti",
          content: (
            <div className={`suseDeo ${isTabletOrLarger ? 'flex' : 'flex flex-col'}`}>
              <div className="suseDeoVeci w-full md:w-2/3 p-4">
                <h3 className="text-lg font-semibold mb-4">Suše u prošlosti</h3>
                <p className="mb-4">Izaberite indeks, mesec i godinu da biste videli sliku:</p>

                <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
                  {/* Filter za SPEI indeks */}
                  <select
                    value={spei}
                    onChange={(e) => setSpei(e.target.value)}
                    className="border p-2 w-full md:w-auto"
                  >
                    <option value="SPEI-3">SPEI-3</option>
                    <option value="SPEI-6">SPEI-6</option>
                    <option value="SPEI-12">SPEI-12</option>
                  </select>

                  {/* Filter za mesec */}
                  <select
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    className="border p-2 w-full md:w-auto"
                  >
                    <option value="Jan">Januar</option>
                    <option value="Feb">Februar</option>
                    <option value="Mar">Mart</option>
                    <option value="Apr">April</option>
                    <option value="May">Maj</option>
                    <option value="June">Jun</option>
                    <option value="July">Jul</option>
                    <option value="Aug">Avgust</option>
                    <option value="Sep">Septembar</option>
                    <option value="Oct">Oktobar</option>
                    <option value="Nov">Novembar</option>
                    <option value="Dec">Decembar</option>
                  </select>

                  {/* Filter za godinu */}
                  <select
                      value={year}
                    onChange={(e) => setYear(e.target.value)}
                    className="border p-2 w-full md:w-auto"
                  >
                    {Array.from({ length: 2023 - 1950 + 1 }, (_, index) => (
                      <option key={index} value={1950 + index}>
                        {1950 + index}
                      </option>
                    ))}
                  </select>

                </div>

                {/* Deo za prikaz slike */}
                <div className="mt-6 w-full md:w-auto">
                  <h4 className="text-lg font-semibold">Prikazana slika za {spei}, {month}, {year}</h4>
                  <img
                    src={getSelectedImage()}
                    alt={`Slika za ${spei}, ${month}, ${year}`}
                    className="w-full md:w-auto max-w-md mx-auto"
                  />
                </div>

                {/* Dugmad za preuzimanje */}
                <div className="mt-6 flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
                  <a
                    href={getSelectedImage()}
                    download={`Slika-${spei}-${month}-${year}.png`}
                    className="bg-blue-500 font-bold text-white px-4 py-2 rounded text-center"
                  >
                    Preuzmi sliku
                  </a>
                  <a
                    href={getDownloadDataLink()}
                    download={`Podaci-${spei}-${month}-${year}.nc`}
                    className="bg-green-500 font-bold text-white px-4 py-2 rounded text-center"
                  >
                    Preuzmi podatke
                  </a>
                  <a
                  href="/spei_netcdf_data.tgz"  // Relativni put do fajla u public folderu
                  download="Suse.tgz"  // Ime fajla pod kojim će se preuzeti
                  className="bg-red-500 font-bold text-white px-4 py-2 rounded text-center"
                >
                  Preuzmi sve podatke
                </a>
                </div>
              </div>

              {/* Deo za dodatni tekst */}
              <div className="suseDeoManji w-full md:w-1/3 p-4 bg-white border-l">
                <h4 className="text-lg font-semibold">SUŠE U PROŠLOSTI</h4>
                <p>Izaberite godinu, mesec i SPEI
                indeks. Ukoliko želite da
                preuzmete umesto slike datoteku
                sa odgovarajućim podacima,
                kliknite na „preuzmi podatke“.
                Ukoliko želite da preuzmete sve
                podatke iz ove sekcije kliknite na
                „preuzmi sve podatke“.</p>
              </div>
            </div>
          ),
        },
        {
          label: "Projekcije za budućnost",
          value: "projekcije-za-buducnost",
          content: (
            <div className={`suseDeo ${isTabletOrLarger ? 'flex' : 'flex flex-col'}`}>
              <div className="suseDeoVeci w-full md:w-2/3 p-4">
                <h3 className="text-lg font-semibold mb-4">Projekcije za budućnost</h3>
                <p className="mb-4">Izaberite indeks, mesec i godinu da biste videli projekciju:</p>

                <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
                  <select
                    value={spei}
                    onChange={(e) => setSpei(e.target.value)}
                    className="border p-2 w-full md:w-auto"
                  >
                    <option value="SPEI-1">SPEI-1</option>
                    <option value="SPEI-3">SPEI-3</option>
                    <option value="SPEI-6">SPEI-6</option>
                    <option value="SPEI-12">SPEI-12</option>
                  </select>

                  {/* Filter za mesec */}
                  <select
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    className="border p-2 w-full md:w-auto"
                  >
                    <option value="Jan">Januar</option>
                    <option value="Feb">Februar</option>
                    <option value="Mar">Mart</option>
                    <option value="Apr">April</option>
                    <option value="May">Maj</option>
                    <option value="June">Jun</option>
                    <option value="July">Jul</option>
                    <option value="Aug">Avgust</option>
                    <option value="Sep">Septembar</option>
                    <option value="Oct">Oktobar</option>
                    <option value="Nov">Novembar</option>
                    <option value="Dec">Decembar</option>
                  </select>

                  <select
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    className="border p-2 w-full md:w-auto"
                  >
                    <option value="2021-2040">2021-2040</option>
                    <option value="2041-2060">2041-2060</option>
                    <option value="2061-2080">2061-2080</option>
                    <option value="2081-2100">2081-2100</option>
                  </select>

                  
                  <select
                    value={variationP}
                    onChange={(e) => setYear(e.target.value)}
                    className="border p-2 w-full md:w-auto"
                  >
                    <option value="25p">25p</option>
                    <option value="50p">50p</option>
                    <option value="70p">70p</option>
                  </select>
                </div>

                {/* Deo za prikaz slike */}
                <div className="mt-6 w-full md:w-auto">
                  <h4 className="text-lg font-semibold">Prikazana projekcija za {spei}, {month}, {year}</h4>
                  <img
                    src={getSelectedImageForProjekcije()}
                    alt={`Projekcija za ${spei}, ${month}, ${year}`}
                    className="w-full md:w-auto max-w-md mx-auto"
                  />
                </div>

                {/* Dugmad za preuzimanje */}
                <div className="mt-6 flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
                  <a
                    href={getSelectedImageForProjekcije()}
                    download={`Projekcija-${spei}-${month}-${year}.png`}
                    className="bg-blue-500 font-bold text-white px-4 py-2 rounded text-center"
                  >
                    Preuzmi projekciju
                  </a>
                  <a
                    href={getDownloadDataLinkForProjekcije()}
                    download={`Podaci-Projekcija-${spei}-${month}-${year}.csv`}
                    className="bg-green-500 font-bold text-white px-4 py-2 rounded text-center"
                  >
                    Preuzmi podatke
                  </a>
                  <a
                    href={getDownloadDataLinkForProjekcije()}
                    download={`Podaci-Projekcija-${spei}-${month}-${year}.csv`}
                    className="bg-red-500 font-bold text-white px-4 py-2 rounded text-center"
                  >
                    Preuzmi sve podatke
                  </a>
                </div>
              </div>

              {/* Deo za dodatni tekst */}
              <div className="suseDeoManji w-full md:w-1/3 p-4 bg-gray-100 border-l">
                <h4 className="text-lg font-semibold">PROJEKCIJE ZA BUDUĆNOST</h4>
                <p>Izaberite godinu, mesec i SPEI
                indeks. Ukoliko želite da
                preuzmete umesto slike datoteku
                sa odgovarajućim podacima,
                kliknite na „preuzmi podatke“.
                Ukoliko želite da preuzmete sve
                podatke iz ove sekcije kliknite na
                „preuzmi sve podatke“.</p>
              </div>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <>
      {isTabletOrLarger && <NavBar />}
      {isSmallDevice && <NavBarMobile />}
      <div className="container mx-auto text-center min-h-screen">
        <Tabs value={activeTab} className="max-w-screen-xl mx-auto">
          <TabsHeader
            className="bg-transparent flex justify-center flex-wrap md:flex-nowrap"
            indicatorProps={{
              className: "bg-gray-900/10 shadow-none !text-gray-900",
            }}
            onChange={(value) => setActiveTab(value)}
          >
            {data.map(({ label, value }) => (
              <Tab className="probaTaba px-2 py-1" key={value} value={value}>
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            {data.map(({ value, tabs, desc, image, desc2 }) => (
              <TabPanel key={value} value={value}>
                {value === "podaci" ? (
                  <Tabs value={activePodaciTab} onChange={(val) => setActivePodaciTab(val)}>
                    <TabsHeader className="bg-gray-200">
                      {tabs.map(({ label, value }) => (
                        <Tab key={value} value={value}>
                          {label}
                        </Tab>
                      ))}
                    </TabsHeader>
                    <TabsBody>
                      {tabs.map(({ value, content }) => (
                        <TabPanel key={value} value={value}>
                          {content}
                        </TabPanel>
                      ))}
                    </TabsBody>
                  </Tabs>
                ) : (
                  <>
                    {/* Prikaz za ostale glavne tabove kao što su Uvod, Metodologija itd. */}
                    <div className="suseDeo flex flex-col md:flex-row">
                      <div className="suseDeoVeci w-full md:w-2/3 p-4">
                        {image && ReactHtmlParser(image)}
                        {desc2 && ReactHtmlParser(desc2)}
                      </div>
                      <div className="suseDeoManji w-full md:w-1/3 p-4 bg-white">
                        {desc && ReactHtmlParser(desc)}
                      </div>
                    </div>
                  </>
                )}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
      <Form />
      <Footer />
    </>
  );
}
