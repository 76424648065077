import React from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function KatalogEN () {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
      return (
    <>
    {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
      <div className="container mx-auto text-center min-h-screen">
            <div className="flex justify-center items-center">
                <h1 className="text-5xl text-blue-400 font-semibold mb-4 text-gray-800 pb-2">
                COMING EXTREMELY SOON
                </h1>
            </div>
    </div>
    <FormEN />
    <FooterEN />
    </>
  );
}